import { FrameObject, LensDataResponse, LensObject, LensPackage, PrescriptionObject } from '../../types/rxConfigurator'
import {
  HOW_TO_READ_YOUR_PRESCRIPTION,
  PUPILLARY_DISTANCE,
  RX_CONFIG,
  RX_CONFIGURATOR_DOM_ID,
  RX_PRESCRIPTION_OBJECT_KEY,
  RX_SAVED_LENS_DATA_KEY,
  RX_SCRIPT_ID,
  RX_STYLE_ID,
} from '../../constants/rxConfigurator'
import React, { useEffect, useState } from 'react'
import {  IProduct } from '../../types/product'
import CurrencyService from '../../services/CurrencyService'
import Log from '../../services/Log'
import { localStorageUtil } from '../../foundation/utils/storageUtil'
import rxConfiguratorService from '../../foundation/apis/rx-config/rx.service'
import { useSite } from '../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { Trans, useTranslation } from 'next-i18next'
import { getQuarterImageUrl } from '../../utils/attachmentsUtils'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getBrand, getCaseImageUrl, getProductType } from '../../utils/productAttributes'
import { fetchCssFromCDN, fetchJsFromCDN } from '../../utils/fetchFromCdn'
import { orderItemsSelector } from '../../features/order/selector'
import { OrderItem } from '../../types/order'
import { getRxLensItem } from '../../utils/rx'
import brandList from '../BrandIcon/brandList'
import DelegateLearnMore from '../DelegateLearnMore'
import { setOpenModalSignIn } from '../../features/ui/action'
import { loginStatusSelector, userDetailsSelector } from '../../redux/selectors/user'
import { useAddOrUpdatePrescriptionMutation } from '../../features/prescription/query'
import SignInDialog from '../SignInDialog'
import config from '@configs/index'
import {
  CORRECTION_TYPE,
  FLOW_TYPE_MAP_KEY,
  createDatabaseRxFormat,
  generateRxListForConfigurator,
  getRxValuesFromRxLens,
  toROXPrescriptionObject,
} from './RxUtils'
import { useGetRxListForRoxMutation } from '../../features/prescription/query'
import { useRoxLensDataQuery } from '../../features/rox/roxApi'
import { currentProductBundleSelector } from '../../features/product/selector'
import ProductService from '../../services/ProductService'
import { isCanadaStore } from '../../utils/storeUtil'
import { pdpFrameImageOrderSelector } from '../../redux/selectors/site'
import { CustomDialog, StyledAnchor } from '../UI'
import { Z_INDEX_DIALOG_LEVEL_FOR_ROX_CONFIGURATOR } from '../../constants/ui'
import * as ROUTES from '../../constants/routes'

const ProductTypes = {
  sun: 'Sun',
  Sun: 'Sun',
  Optical: 'Optics',
  optical: 'Optics',
  Optics: 'Optics',
  optics: 'Optics',
} as const
export interface ImageryType {
  fallbackImage: string
  productImage: string
}

export interface PrescriptionLensesProps {
  categoryHref?: string | null
  /**
   * @property {string[]} catEntryIdsOfLensPackages - To search for lensPackages with provided catEntryIds
   * among all lens packages from getLensesData request.
   * It currently results in finding one main lens package, which includes lenses and services.
   * Used for uploading the lens package of cart item (orderItem) to configurator.
   */
  catEntryIdsOfLensPackages?: string[]
  product: IProduct
  onAddToBag(
    _frameObject: FrameObject,
    _lensObject: LensObject,
    _warrantyObject,
    _reviewObject,
    imagery: ImageryType
  ): void
  onClose: (isAddToCart?: boolean) => void
  isFromCart?: boolean
}

// TODO: can read directly from selectedOrderItem and bypass searching within orderItems
const getOrderProduct = (product: IProduct) => (i: OrderItem) => i.partNumber === product.partNumber

const checkIfComponentAlreadyLoaded = (elementId: string) => !!document.getElementById(elementId)

function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

const PrescriptionLenses: React.FC<PrescriptionLensesProps> = ({
  categoryHref,
  catEntryIdsOfLensPackages,
  product,
  onAddToBag,
  onClose,
  isFromCart,
}) => {
  const { mySite } = useSite()
  const { basePath } = useStoreIdentity()
  const { t } = useTranslation()
  const [contentId, setContentId] = useState('')
  const [showLearnMoreModal, setShowLearnMoreModal] = useState<boolean>(false)
  //ROX only accepts the format xx_XX
  const locale = mySite?.locale || config.defaultLocale
  const LANG = locale.replace(locale.substring(2), locale.substring(2).toUpperCase())
  const { langCode } = useStoreIdentity()
  let hasLoggedInFromModal = false
  let userLoggedIn = !!useSelector(loginStatusSelector)
  let userDetails = useSelector(userDetailsSelector)
  const [hasInvalidRx, setHasInvalidRx] = useState<boolean>(false)
  const [hasShownInvalidRXAlert, seHasShownInvalidRXAlert] = useState<boolean>(false)
  const langId = mySite.langId
  const dispatch = useDispatch()
  const pdpFrameImageOrder = useSelector(pdpFrameImageOrderSelector, shallowEqual)

  const { data: lensData, isError: isLensDataError } = useRoxLensDataQuery(
    { productId: product?.partNumber ?? '', langId },
    { skip: !product, refetchOnMountOrArgChange: true }
  )

  const [addOrUpdatePrescriptionMutation] = useAddOrUpdatePrescriptionMutation()
  const { orderItem } = useSelector(currentProductBundleSelector)
  const orderItems = useSelector(orderItemsSelector)
  const [getRxListMutation] = useGetRxListForRoxMutation()
  const isRxcV3Enabled = config?.rxc?.enableRxcV3 ?? false /* TODO: remove when RXC 3.0 is live */
  const RX_SCRIPT_SRC = isRxcV3Enabled ? config?.rxc?.rxcV3Src ?? '' : mySite?.xStoreCfg?.RX_SCRIPT_SRC ?? ''
  const RX_STYLE_SRC = mySite.xStoreCfg.RX_STYLE_SRC || '' /* TODO: remove when RXC 3.0 is live */
  const brandName = getBrand(product)
  const productType = getProductType(product)

  const currentProductType = ProductTypes[productType]

  const largeIconLayout = mySite.xStoreCfg.largeIconLayout?.[brandName]?.[currentProductType] || 'false'

  const enableDesignTypeStep = mySite.xStoreCfg.enableDesignTypeStep?.[brandName]?.[currentProductType] || 'false'

  const layoutSettings = {
    showBundlePrice: false,
    largeIconLayout: Boolean(largeIconLayout),
    enableDesignTypeStep: Boolean(enableDesignTypeStep),
    addTilesTitlePrefix: ['designType'],
    delegatingLearnMore: true,
    eligibleForInsurance: isCanadaStore(mySite.locale),
    enableGrayout: config?.rxc?.enableGrayout ?? false,
    enableLargeIcons: config?.rxc?.enableLargeIcons ?? false,
    tilesFeaturesListLayout: 'BULLET_POINTS',
  }

  const checkAvailableFrames = (_frame, _prescriptionObject) => {
    return `${basePath}${categoryHref}`
  }

  const downloadExtendedPrescription = requestObject => {
    return new Promise(function (resolve, reject) {
      rxConfiguratorService
        .downloadPrescriptionFile(mySite, requestObject.savedFileName)
        .then(({ data, headers }) => {
          const base64Prescription = Buffer.from(data, 'binary').toString('base64')
          fetch(`data:${headers['content-type']};base64,${base64Prescription}`)
            .then(res =>
              res
                .blob()
                .then(fileData => {
                  resolve({ fileData: URL.createObjectURL(fileData) })
                })
                .catch(reject)
            )
            .catch(reject)
        })
        .catch(reject)
    })
  }

  const uploadExtendedPrescription = async ({ fileData, fileName }) => {
    const formData = new FormData()
    formData.append('UpLoadedFile', dataURLtoFile(fileData, fileName))
    return new Promise((resolve, reject) => {
      rxConfiguratorService
        .uploadFileFromConfigurator(mySite, formData)
        .then(({ data }) => {
          resolve({
            fileName,
            prescriptionId: data.rxFileStorageId,
            savedFileName: data.rxFileStorageId,
          })
        })
        .catch(() => reject())
    })
  }

  const savePrescription = prescriptionObject => {
    try {
      localStorageUtil.set(RX_PRESCRIPTION_OBJECT_KEY, prescriptionObject)
      return true
    } catch (e) {
      Log.error('Error saving prescription to local Storage: ' + e)
      return false
    }
  }

  const saveExtendedPrescription = prescriptionObject => {
    const prescriptionFlow = prescriptionObject?.prescriptionFlow
    if (RX_CONFIG.prescriptionModule.prescriptionFlows.includes(prescriptionFlow)) {
      return new Promise((resolve, reject) => {
        try {
          localStorageUtil.set(RX_PRESCRIPTION_OBJECT_KEY, prescriptionObject)
          resolve(prescriptionObject)
        } catch (e) {
          Log.error('Error saving extended prescription: ' + e)
          reject()
        }
      })
    } else {
      return new Promise((_, reject) => reject)
    }
  }

  const loadLearnMoreContent = (contentName: string) => {
    return new Promise(resolve => {
      const CONTENT = contentName.split('_')
      if (CONTENT[2] === 'HowToReadPrescription') {
        resolve(HOW_TO_READ_YOUR_PRESCRIPTION(t))
      } else if (CONTENT[2] === 'PupillaryDistance') {
        resolve(PUPILLARY_DISTANCE(t))
      }
    })
  }

  const isLogged = () => {
    return userLoggedIn || hasLoggedInFromModal
  }
  const login = callback => {
    dispatch(
      setOpenModalSignIn(
        true,
        () => {
          hasLoggedInFromModal = true
          callback(true)
        },
        () => {
          callback(false)
          hasLoggedInFromModal = false
        }
      )
    )
  }

  const saveToMyAccount = prescriptionObject => {
    if (prescriptionObject) {
      const body = createDatabaseRxFormat(prescriptionObject, userDetails, mySite.storeID)

      addOrUpdatePrescriptionMutation(body)
        .unwrap()
        .then(result => {
          if (result.errorCode) {
            Log.error('Error Saving RX: ' + result)
          } else {
            Log.info('RX saved to My account')
            return prescriptionObject
          }
        })
        .catch(error => {
          Log.error('Error Saving RX: ' + error)
        })
    } else {
      Log.error('The RX cannot be null or empty')
    }
  }

  const retrieveFromMyAccount = async () => {
    const rxList = await getRxListMutation({})
      .unwrap()
      .then(res => {
        const rxListForRox = generateRxListForConfigurator(res.Prescription || [])
        setHasInvalidRx(rxListForRox.hasInvalidRx)
        return rxListForRox.prescriptions
      })
      .catch((error: any) => {
        Log.error('Error retrieving RX for ROX:' + error)
        return []
      })
    return new Promise(function (resolve) {
      resolve(rxList)
    })
  }

  const delegateLearnMoreContent = _contentIdentifier => {
    setContentId(_contentIdentifier)
  }

  const loadExtendedPrescription = () => {
    return new Promise(function (resolve, reject) {
      const rxLens = getRxLensItem(orderItem)
      if (isFromCart && rxLens) {
        const rxObject = getRxValuesFromRxLens(rxLens, { fallbackValue: '' })
        let rxType = CORRECTION_TYPE.SINGLE_VISION
        if (orderItem) {
          const rxFLowTypeString = localStorageUtil.get(FLOW_TYPE_MAP_KEY) || '[]'
          let rxFlowTypeMap = new Map<string, string>(JSON.parse(rxFLowTypeString))
          rxType = rxFlowTypeMap.get(orderItem.orderItemId) || CORRECTION_TYPE.SINGLE_VISION
        }

        const prescriptionObject: PrescriptionObject = {
          prescriptionFlow: 'MANUAL',
          rxType,
          PRISM_ENABLED: false,
          ...toROXPrescriptionObject(rxObject),
        }
        // Need to save to local storage for case where user does not edit the prescription and proceeds to save.
        // In this scenario the saveExtendedPrescription (save to local storage) is never called resulting in the
        // prescription being blanked out. Also, saving the rxflowType in the local storage so ROX reads it and show the proper
        // type in the review

        localStorageUtil.set(RX_PRESCRIPTION_OBJECT_KEY, prescriptionObject)

        resolve(prescriptionObject)
      }
      reject(null)
    })
  }

  /**
   * @returns deep clone of lensdata -- needed because RTK results prevent extensions and
   * ROX will mutate the lensdata
   */
  const getEditableLensData = (): LensDataResponse | undefined => {
    return lensData
      ? {
          ...lensData,
          data: {
            ...lensData.data,
            frame: { ...lensData.data.frame },
          },
          translation: {
            language: LANG,
          },
          lensesData: {
            ...lensData.lensesData,
            packages: lensData.lensesData?.packages?.map(p => {
              return {
                ...p,
                frame: { ...p.frame },
                lensPackage: { ...p.lensPackage },
              }
            }),
          },
        }
      : undefined
  }

  /**
   * Once we have the lens data, fetch ROX widget and render it
   */
  const fetchRxc = () => {
    const data = getEditableLensData()
    if (!product || !data) return

    const frameUpc = product?.partNumber
    const orderProductIndex = orderItems?.findIndex(getOrderProduct(product))
    const rxLens = getRxLensItem(orderItem)
    const brandLogo = brandList.find(brand => brand.name === brandName)?.logo || null
    const quarterimageUrl = getQuarterImage(product, pdpFrameImageOrder, mySite)

    data.data.frame.brandImageUrl = brandLogo
    data.data.frame.imageUrl = quarterimageUrl
    data.data['showFrameOnly'] = true
    data.data.lens =
      isFromCart && rxLens
        ? {
            catEntryId: rxLens.productId,
          }
        : undefined
    const updatedConfig = {
      ...RX_CONFIG,
      ...data,
      layoutSettings,
      cartMode: isFromCart
        ? {
            orderItemId: orderItem?.orderItemId,
            orderIndex: orderProductIndex,
          }
        : undefined,
    }

    if (catEntryIdsOfLensPackages) {
      const packageWithCatEntryId = data.lensesData.packages.find(({ lensPackage }) =>
        catEntryIdsOfLensPackages.find(catEntryId => catEntryId === lensPackage.catEntryId)
      )?.lensPackage

      if (packageWithCatEntryId) {
        updatedConfig.data['lens'] = packageWithCatEntryId
      }
    } else if (!isFromCart) {
      const savedLensData = localStorageUtil.get(RX_SAVED_LENS_DATA_KEY) as {
        _frameUpc: string
      } & LensPackage
      if (savedLensData && savedLensData._frameUpc === frameUpc) {
        updatedConfig.data['lens'] = savedLensData
        delete updatedConfig.data['lens']._frameUpc
      }
    }

    updatedConfig.actionsModule['genericAddToCart'] = (
      _frameObject: FrameObject,
      _lensObject: LensObject,
      _warrantyObject,
      _reviewObject,
      imagery: ImageryType
    ) => {
      onAddToBag(_frameObject, _lensObject, _warrantyObject, _reviewObject, imagery)
      onClose(true)
    }
    updatedConfig.actionsModule['genericSaveEditFromCart'] = (
      _frameObject: FrameObject,
      _lensObject: LensObject,
      _warrantyObject,
      _reviewObject,
      imagery: ImageryType
    ) => {
      onAddToBag(_frameObject, _lensObject, _warrantyObject, _reviewObject, imagery)
      onClose(true)
    }

    updatedConfig.actionsModule['genericExit'] = onClose
    updatedConfig.actionsModule['loadLearnMoreContent'] = loadLearnMoreContent
    updatedConfig.actionsModule['delegateLearnMoreContent'] = delegateLearnMoreContent
    updatedConfig.actionsModule['loadContent'] = () => {}
    updatedConfig.prescriptionModule['checkAvailableFrames'] = checkAvailableFrames
    updatedConfig.prescriptionModule['savePrescription'] = savePrescription
    updatedConfig.prescriptionModule['uploadExtendedPrescription'] = uploadExtendedPrescription
    updatedConfig.prescriptionModule['downloadExtendedPrescription'] = downloadExtendedPrescription
    updatedConfig.prescriptionModule['saveExtendedPrescription'] = saveExtendedPrescription
    updatedConfig.prescriptionModule['retrieveFromMyAccount'] = retrieveFromMyAccount
    updatedConfig.prescriptionModule['saveToMyAccount'] = saveToMyAccount
    updatedConfig.prescriptionModule['loadExtendedPrescription'] = loadExtendedPrescription
    updatedConfig.currencyFormat = CurrencyService.getFormattedPriceForRoxConfigurator(
      mySite.locale,
      mySite.defaultCurrencyID
    )
    updatedConfig.loginModule['isLoggedIn'] = isLogged
    updatedConfig.loginModule['login'] = login
    updatedConfig['translation'] = { language: LANG }

   if (isRxcV3Enabled) {
      fetchJsFromCDN(RX_SCRIPT_SRC, 'RXC', { id: RX_SCRIPT_ID, crossOrigin: 'anonymous' }).catch(error => {
        Log.error('Error loading RXC script: ' +error)
        onClose()
      })
  
      window.addEventListener('RXC_LOADED', () => {
        const myWidget = window?.RXC?.rxcWidget.new(updatedConfig)
        myWidget.render()
      })
    } else {
      /* TODO: remove when RXC 3.0 project is complete */
      fetchJsFromCDN(RX_SCRIPT_SRC, 'RXC', { id: RX_SCRIPT_ID, crossOrigin: 'anonymous' })
      .then(RXC => {
        const myWidget = RXC.rxcWidget.new(updatedConfig)

        myWidget.render()
      })
      .catch(error => {
        Log.error('Error loading RXC script: ' +error)
        onClose()
      })
    }
  }

  const fetchRxcStyle = () => {
    fetchCssFromCDN(RX_STYLE_SRC, RX_STYLE_ID)
  }

  const overrideConfiguratorTranslation = (): void => {
    // @ts-ignore
    window.rxcTranslations = {
      [LANG]: {
        steps: {
          advancedPrescription: {
            later: {
              card: {
                description: t('PrescriptionConfigurator.Labels.AdvancedPrescription.Description'),
              },
            },
            manual: {
              howToReadModal: {
                customerService: t('PrescriptionConfigurator.Labels.AdvancedPrescription.CustomerService'),
              },
            },
          },
        },
      },
    }
  }

  const loadRoxStyles = () => {
    if (!checkIfComponentAlreadyLoaded(RX_STYLE_ID)) {
      fetchRxcStyle()
    }
  }

  const loadRoxWidget = () => {
    if (!checkIfComponentAlreadyLoaded(RX_SCRIPT_ID)) {
      fetchRxc()
    }
  }

  /**
   * Init and cleanup
   */
  useEffect(() => {
    overrideConfiguratorTranslation()

    return () => {
      const rxStyle = document.getElementById(RX_STYLE_ID)
      const rxScript = document.getElementById(RX_SCRIPT_ID)
      if (rxStyle) {
        document.body.removeChild(rxStyle)
      }
      if (rxScript) {
        document.body.removeChild(rxScript)
      }
    }
  }, [])

  /**
   * Load ROX styles and widget once we have lens data and images
   */
  useEffect(() => {
    if (lensData) {
      /* TODO: remove when RXC 3.0 is live */
      if (!isRxcV3Enabled) {
        loadRoxStyles()
      }
      loadRoxWidget()
    }
  }, [lensData])

  /**
   * Lens data load error
   */
  useEffect(() => {
    if (isLensDataError) {
      onClose()
    }
  }, [isLensDataError])

  useEffect(() => {
    setShowLearnMoreModal(contentId ? true : false)
  }, [contentId])

  useEffect(() => {
    if (hasLoggedInFromModal) {
      userDetails = useSelector(userDetailsSelector)
    }
  }, [hasLoggedInFromModal])

  return (
    <>
      <div id={RX_CONFIGURATOR_DOM_ID} />
      {!hasShownInvalidRXAlert && hasInvalidRx && (
        //Because ROX might make multiple calls to this function, I want to restrict it to show the alert only once
        <CustomDialog
          customZIndex={Z_INDEX_DIALOG_LEVEL_FOR_ROX_CONFIGURATOR}
          autoOpen={true}
          onCloseAction={() => {
            seHasShownInvalidRXAlert(true)
          }}
          confirmButton={{
            label: t('PrescriptionConfigurator.Labels.Dialog.OK'),
            onclick: () => {
              seHasShownInvalidRXAlert(true)
            },
          }}
          content={
            <Trans i18nKey="PrescriptionConfigurator.Messages.InvalidPrescriptionsAlert">
              {{ myAccountLink: t('Header.Actions.Account') }}
              <StyledAnchor
                href={`${basePath}/${langCode}/${ROUTES.ACCOUNTWITHOUTPARAM}/${ROUTES.ACCOUNT_CHILDREN.PRESCRIPTIONS}`}
              />
            </Trans>
          }
        />
      )}
      {showLearnMoreModal && <DelegateLearnMore contentIdentifier={contentId} onModalClose={() => setContentId('')} />}
      {<SignInDialog />}
    </>
  )
}

const getQuarterImage = (product: IProduct, pdpFrameImageOrder, mySite) => {
  const PDPImagesArray = ProductService.getProductImages(getCaseImageUrl(product), product) || []
  return getQuarterImageUrl(mySite, PDPImagesArray, pdpFrameImageOrder)
}

export default PrescriptionLenses
