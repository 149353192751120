import React from 'react'
import PrescriptionLenses from './PrescriptionLenses'
import { useDispatch, useSelector } from 'react-redux'
import { isAddingItemSelector } from '../../features/order/selector'
import { useRouter } from 'next/navigation'
import { CART } from '../../constants/routes'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { setAddToCartBusy, startEditingCartItem } from '../../features/cartui/cartuiSlice'
import { setPrescriptionLensFormOpen } from '../../features/rox/roxSlice'
import { useEditCartItemParams } from '../../hooks/useEditCartItemParams'
import { currentProductBundleSelector } from '../../features/product/selector'
import { useAddOrderItem } from '@views/ProductDetails/hooks/useAddOrderItem'
import CartSkeletonLoader from '@views/Cart/components/CartSkeletonLoader'
import { virtualMirrorUtils } from '@utils/virtualMirrorUtils'

export const Prescription: React.FC<{
  setSelectLensesBusy?: React.Dispatch<boolean>
}> = (props: { setSelectLensesBusy?: React.Dispatch<boolean> }) => {
  const { langCode } = useStoreIdentity()
  const router = useRouter()
  const dispatch = useDispatch()
  const { isEditLens } = useEditCartItemParams()

  const { product, orderItem } = useSelector(currentProductBundleSelector)
  const isAddingToCart = useSelector(isAddingItemSelector)

  const { handleAddToCartLenses } = useAddOrderItem(orderItem, product, product?.partNumber, ['1'])

  const addToBag = (_, lensObject, _reviewObject, ...args) => {
    if (isEditLens) {
      dispatch(startEditingCartItem())
    }

    dispatch(setPrescriptionLensFormOpen(false))
    handleAddToCartLenses(_, lensObject, args)
    virtualMirrorUtils.unmountVirtualMirror()
  }

  const onClose = (isAddToCart?: boolean) => {
    props.setSelectLensesBusy && props.setSelectLensesBusy(isAddToCart || false)
    dispatch(setAddToCartBusy(false))
    dispatch(setPrescriptionLensFormOpen(false))
    if (isEditLens) {
      router.push(`/${langCode}/${CART}`)
    }
  }

  if (!product) return null

  return (
    (isAddingToCart && <CartSkeletonLoader />) || (
      <PrescriptionLenses
        isFromCart={isEditLens}
        product={product}
        onClose={onClose}
        onAddToBag={addToBag}
        categoryHref={product?.seo?.href}
      />
    )
  )
}
