import React, { useCallback, useEffect, useState } from 'react'
import Axios, { Canceler } from 'axios'
//CONSTS
import { PO_NUMBER } from '../../../constants/order'
import {
  PAYMENT_METHODS,
  PAYMENT_STEPS,
  SECURE_PAYMENT_CODES,
  SECURE_PAYMENT_ERROR_CODES,
  SECURE_PAYMENT_SUCCESS_CODES,
} from '../../../constants/paymentMethods'
import { ACCOUNT, NEWSLETTER } from '../../../foundation/constants/common'
//APIS
import { orderApi } from '../../../features/order/query'
//REDUX
import { useDispatch, useSelector } from 'react-redux'
import {
  cartProductCountSelector,
  cartSelector,
  catentriesSelector,
  orderFinalizingSelector,
  orderItemsSelector,
  paypalExpressSelector,
  selectedPayMethodsSelector,
} from '../../../features/order/selector'
import { firstAvailableEmailContactSelector, userDetailsSelector } from '../../../redux/selectors/user'
import { VALIDATION_ERROR_ACTION } from '../../../redux/actions/error'
import { currentContractIdSelector } from '../../../redux/selectors/contract'
import fetchPayMethods from '../../../features/order/thunks/fetchPayMethods'
//TYPES
import { CheckoutPayload, CreditCardFormDataType, PaymentMethodStatus } from '../../../types/checkout'
import { IProduct } from '../../../types/product'
//UTILS
import getDisplayName from 'react-display-name'
import { localStorageUtil } from '../../../foundation/utils/storageUtil'

//HOOKS
import { useSite } from '../../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { usePayPal } from './usePaypal'
import { useKlarna } from './useKlarna'
import { usePayment } from './usePayment'
import { useSecurePayment } from './useSecurePayment'
import {
  getPartNumberQuantities,
  sendNewsletterSubscriptionEvent,
  sendPaymentEvent,
} from '../../../foundation/analytics/tealium/lib'
import { ANALYTICS_PAGE_TYPE, useAnalyticsData } from '../../../foundation/hooks/useAnalyticsData'
import { useApplePay } from './useApplePay'
import { getCheckoutPaths } from '../../../utils/routeUtils'
import { useTranslation } from 'next-i18next'
//LIBS
import { isEqual, values } from 'lodash-es'
import Log from '../../../services/Log'
//COMPONENTS
import { SelectPaymentContainer, TotalZeroButtonWrapper, TotalZeroContainer, TotalZeroLabel } from './Payment.style'
import { canUseApplePay } from '../../../utils/payMethods'
import { APPLEPAY_ORDER_ID } from '../../../constants/checkout'
import { Button, GridItem } from '../../../components/UI'
import { PaymentMethodSelection } from '../components/PaymentMethodSelection'
import { getCatEntriesAlgoliaPrices } from '../../../foundation/algolia/algoliaPrice'
import { isRxCart, parseOrderItems } from '../../../utils/isRxOrder'
import { useZeroCheckout } from './useZeroCheckout'
import { useRouter, useSearchParams } from 'next/navigation'
import { useSubscribeNewsletterMutation } from '@features/newsletter/query'
import { CART, CHECKOUT } from '@constants/routes'
import { parseCatentriesForRX } from '@foundation/analytics/tealium/formatters/productFormatter'
import { HTTP_CODE_OK } from '@constants/common'
import { getSubscribedItems, hasSubscribedItemsInOrder } from '@views/Subscription/helpers/subscriptionHelpers'
import { Dialog } from '@mui/material'
import { SaveCreditCardDialog } from './SaveCreditCardDialog/SaveCreditCardDialog'
import { isNewsletterSubscriptionRequestedSelector } from '@features/checkout/selector'
import { useCheckoutSteps } from '@hooks/useCheckoutSteps'
import productUtils from '@utils/ProductUtils'
import { useCustomerSegmentsUtil } from '@utils/Cookies'

const CancelToken = Axios.CancelToken
let cancels: Canceler[] = []

/**
 * Payment section
 * displays payment method and billing address selection
 * @param props
 */
const Payment: React.FC = () => {
  const { t } = useTranslation()
  const [paymentMethodStatus, setPaymentMethodStatus] = useState<PaymentMethodStatus>({
    '? Affirm': { isSelected: false },
    ApplePay: { isSelected: false },
    CyberSourceAmex: { isSelected: false },
    CyberSourceDiscover: { isSelected: false },
    CyberSourceKlarna: { isSelected: false },
    CyberSourceMC: { isSelected: false },
    CyberSourceVisa: { isSelected: false },
    PayPal: { isSelected: false },
    cod: { isSelected: false },
    Zero: { isSelected: false },
  })

  const [webId, setWebId] = useState('')
  const payerAuthFormRef = React.useRef<HTMLFormElement>(null)
  const klarnaPaymentRef = React.useRef<HTMLDivElement>(null)
  const selectedPaymentInfoList = useSelector(selectedPayMethodsSelector)
  const [deleteAllPaymentInstructions] = orderApi.endpoints.deleteAllPaymentInstructions.useLazyQuery()
  const [triggerAddPaymentInstruction] = orderApi.endpoints.addPaymentInstruction.useLazyQuery()
  const [isPaymentThroughWallet, setPaymentThroughWallet] = useState<boolean>(false)
  const userDetails = useSelector(userDetailsSelector)
  const [searchParams] = useSearchParams()
  const { completeCheckoutStep } = useCheckoutSteps()
  const [isUpdatingPaymentInstructions, setIsUpdatingPaymentInstructions] = useState(false)
  const [showSaveCCDialog, setShowSaveCCDialog] = useState(false)
  const {
    paymentInfo,
    paymentLoading,
    paymentMethods,
    togglePayOption,
    submit,
    setPaymentLoading,
    onSecurePaymentSetup,
    securePaymentStatus,
    setSecurePaymentStatus,
  } = usePayment(webId)

  const { zeroCheckoutPaymentLoading, submitZeroCheckout } = useZeroCheckout(webId)

  const { paypalFormSubmitting, paypalStatus, setPayPalStatus, continueWithPayPal } = usePayPal()

  const { initializeSession, applePayStatus, initializeApplePay } = useApplePay('classic')

  const { payerAuthFormSubmit, PayerAuthIFrame, SecurePaymentForm, getPayerAuthEnrollData } = useSecurePayment()

  const { initKlarnaSession, klarnaStatus, authorizeKlarna, submitOrderWithKlarnaKlarna } = useKlarna(
    klarnaPaymentRef,
    paymentMethodStatus.CyberSourceKlarna.isSelected
  )

  const widgetName = getDisplayName(Payment)
  const router = useRouter()
  const contractId = useSelector(currentContractIdSelector)
  const cart = useSelector(cartSelector)
  const shipMode = cart.orderItem?.[0]?.shipModeDescription
  const firstAvailableEmailContact = useSelector(firstAvailableEmailContactSelector)
  const orderFinalizing = useSelector(orderFinalizingSelector)
  const { ...analyticsDataForPayment } = useAnalyticsData(ANALYTICS_PAGE_TYPE.PAYMENT)
  const catEntries = useSelector(catentriesSelector, isEqual)
  const paypalExpressStatus = useSelector(paypalExpressSelector)
  const { langCode } = useStoreIdentity()
  const dispatch = useDispatch()
  const { mySite } = useSite()
  const checkoutPaths = getCheckoutPaths(langCode)
  const [subscribeNewsletter] = useSubscribeNewsletterMutation()
  const [paymentProcessing, setPaymentProcessing] = useState<boolean>(false)
  const [poNumber, setPONumber] = useState<string>('')
  const hasSubscriptionInCart = hasSubscribedItemsInOrder(cart, null)

  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : ''
  const payloadBase: CheckoutPayload = {
    currency: defaultCurrencyID,
    contractId: contractId,
    storeId: mySite.storeID,
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const [getCart] = orderApi.endpoints.getCart.useLazyQuery()
  const orderItems = useSelector(orderItemsSelector)
  const cartItemsCount = useSelector(cartProductCountSelector)
  const hasSubscribedItems = !!getSubscribedItems(orderItems).length
  const isNewsletterSubscriptionRequested = useSelector(isNewsletterSubscriptionRequestedSelector)
  //TODO we will remove this check  on phase 2 when we implement multiple items with subscriptions
  const hasSubscriptionMultipleItemsError = cartItemsCount > 1 && hasSubscribedItems
  const customerSegment = useCustomerSegmentsUtil()
  const algoliaPrices = getCatEntriesAlgoliaPrices(customerSegment, catEntries)
  const isRxInCart = isRxCart(cart?.orderExtendAttribute)
  const parsedOrderItems = isRxInCart ? parseOrderItems(cart.orderItem) : cart.orderItem

  useEffect(() => {
    localStorageUtil.set('ON_BEHALF_FORTER_WEB_ID', webId)
  }, [webId])

  useEffect(() => {
    getCart({
      ...payloadBase,
      fetchCatentries: false,
      fetchShippingInfo: false,
      refetch: false,
    })

    return () => {
      localStorageUtil.remove('isApplePaySuccess')
      localStorageUtil.remove('isKlarnaSuccess')
    }
  }, [])

  useEffect(() => {
    paypalExpressStatus?.redirecturl && paypalExpressStatus?.isSelected && router.push(paypalExpressStatus.redirecturl)
    paymentMethodStatus.PayPal.isSelected = paypalExpressStatus?.isSelected
  }, [paypalExpressStatus])

  const onSubmit = async (formData: CreditCardFormDataType | null, isUsingWallet?: boolean) => {
    const email = userDetails?.contact?.[0].email1 || ''
    const firstName = userDetails?.contact?.[0].firstName || ''
    if (!isUsingWallet && hasSubscribedItems && !formData?.createWallet) {
      setShowSaveCCDialog(true)
      return
    }

    const shouldSubscribeToNewsletter = !userDetails?.x_data?.hasNewsletter && isNewsletterSubscriptionRequested
    if (shouldSubscribeToNewsletter) {
      subscribeNewsletter({
        firstName,
        from: CHECKOUT,
        email,
        newsletter_id: mySite.newsletterType || NEWSLETTER,
      })
        .unwrap()
        .then((result: any) => {
          if (result?.code === HTTP_CODE_OK) {
            sendNewsletterSubscriptionEvent(email?.toLowerCase())
          }
        })
        .catch(error => {
          Log.error(error)
        })
    }

    await submitPayment(formData, isUsingWallet)
  }

  const submitPayment = async (formData, isUsingWallet) => {
    setPaymentThroughWallet(!!isUsingWallet)
    switch (true) {
      case paymentMethodStatus.PayPal.isSelected:
        setPayPalStatus({ loading: true })
        await continueWithPayPal('false')
        break
      case paymentMethodStatus.ApplePay.isSelected:
        void initializeSession()
        break
      case paymentMethodStatus.CyberSourceKlarna.isSelected && klarnaStatus.loaded && !klarnaStatus.authorized:
        void authorizeKlarna()
        break
      case parseFloat(cart?.grandTotal) === 0:
        void submitZeroCheckout()
        break
      default:
        void submit(formData)
    }
  }

  const handleSecurePaymentStatus = useCallback(async () => {
    const { step, data, error, shouldAuthenticate } = securePaymentStatus
    Log.info('PAYMENT STATUS: ' + JSON.stringify(securePaymentStatus))
    if (error) {
      const errorMessage =
        data?.reasonCode && SECURE_PAYMENT_ERROR_CODES.includes(data?.reasonCode)
          ? t(`Payment.Msgs.SecurePaymentErrors.error${data?.reasonCode}`)
          : t('Payment.Msgs.SecurePaymentErrors.genericError')
      dispatch(
        VALIDATION_ERROR_ACTION({
          errorMessage: errorMessage,
        })
      )
      setPaymentLoading(false)
      return
    }

    if (step === PAYMENT_STEPS.SETUP && data) {
      payerAuthFormSubmit(payerAuthFormRef)
    }

    if (step === PAYMENT_STEPS.SETUP || step === PAYMENT_STEPS.FINALIZATION) {
      window.addEventListener('message', onSecurePaymentSetup, false)
    }

    if (step === PAYMENT_STEPS.FINALIZATION && !error && shouldAuthenticate) {
      payerAuthFormSubmit(payerAuthFormRef)
    }

    if (step === PAYMENT_STEPS.FINALIZATION && !error && !shouldAuthenticate) {
      completeCheckoutStep('payment')
      router.push(checkoutPaths['order-confirmation'])
    }

    if (securePaymentStatus?.step === PAYMENT_STEPS.ENROLLMENT && !securePaymentStatus.error) {
      try {
        const res = await getPayerAuthEnrollData().catch(e => {
          throw e
        })

        setSecurePaymentStatus({
          ...securePaymentStatus,
          step: PAYMENT_STEPS.FINALIZATION,
          data: res.data,
          error: !SECURE_PAYMENT_SUCCESS_CODES.includes(res.data?.reasonCode),
          shouldAuthenticate: res.data?.reasonCode === SECURE_PAYMENT_CODES[475],
        })
      } catch (e) {
        setPaymentLoading(false)
        setSecurePaymentStatus({
          error: true,
        })
      }
    }
    return () => {
      window.removeEventListener('message', onSecurePaymentSetup)
    }
  }, [securePaymentStatus])

  const initPayments = async () => {
    try {
      switch (true) {
        case paymentMethodStatus.ApplePay.isSelected:
          canUseApplePay() && (await initializeApplePay())
          break
        case paymentMethodStatus.CyberSourceKlarna.isSelected:
          await initKlarnaSession()
          break
        default:
          localStorageUtil.remove(APPLEPAY_ORDER_ID)
      }
    } catch (e: any) {
      Log.error('PAYMENT INIT ERROR: ' + e, window.location.href)
    }
  }

  useEffect(() => {
    if (mySite) {
      dispatch(fetchPayMethods(payloadBase))
    }
    return () => {
      cancels.forEach(cancel => cancel())
    }
  }, [mySite, dispatch])

  useEffect(() => {
    if (cart && poNumber === '') {
      const poNumberFromStorage = localStorageUtil.get(ACCOUNT + '-' + PO_NUMBER + '-' + cart.orderId)

      if (poNumberFromStorage !== null && poNumberFromStorage !== '') {
        setPONumber(poNumberFromStorage)
      }
    }
  }, [cart, poNumber])

  useEffect(() => {
    if (catEntries && Object.keys(catEntries).length > 0) {
      const products = values(catEntries) as IProduct[]
      const partNumberQuantities = getPartNumberQuantities(cart.orderItem)
      const isOptin = searchParams?.['acceptedNewsletterFlag'] === '1'
      if (isRxInCart) {
        const frameCatEntries = parseCatentriesForRX(parsedOrderItems, products, algoliaPrices)
        const combinedAlgoliaPrices = productUtils.combineAlgoliaOpticalPrices(frameCatEntries, algoliaPrices)

        sendPaymentEvent({
          common: analyticsDataForPayment,
          products: frameCatEntries,
          partNumberQuantities,
          email: firstAvailableEmailContact,
          isOptin,
          shipMode,
          algoliaPrices: combinedAlgoliaPrices,
        })
      } else {
        sendPaymentEvent({
          common: analyticsDataForPayment,
          products,
          partNumberQuantities,
          email: firstAvailableEmailContact,
          isOptin,
          shipMode,
          algoliaPrices,
        })
      }
    }
  }, [userDetails])

  useEffect(() => {
    void initPayments()
  }, [
    paymentMethodStatus.PayPal.isSelected,
    paymentMethodStatus.CyberSourceKlarna.isSelected,
    paymentMethodStatus.ApplePay.isSelected,
  ])

  useEffect(() => {
    void handleSecurePaymentStatus()
  }, [securePaymentStatus])

  useEffect(() => {
    //we dont want to reset the payment instructions for saved CC
    if (!isPaymentThroughWallet && !isUpdatingPaymentInstructions && !hasSubscriptionInCart) {
      void updatePaymentInstructions()
    }
  }, [selectedPaymentInfoList, hasSubscriptionInCart, isPaymentThroughWallet])

  const updatePaymentInstructions = useCallback(async () => {
    try {
      setIsUpdatingPaymentInstructions(true)
      const selectedPaymentMethodData = selectedPaymentInfoList.find(payMethodId => !!payMethodId)

      const selectedPaymentMethodId = selectedPaymentMethodData?.payMethodId

      if (!selectedPaymentMethodData?.creditCardFormData.cc_account) {
        return
      }

      await deleteAllPaymentInstructions(payloadBase)

      selectedPaymentMethodId &&
        PAYMENT_METHODS.CHECKOUT_CC_NAMES.includes(selectedPaymentMethodId) &&
        (await triggerAddPaymentInstruction({
          ...payloadBase,
          body: {
            ...selectedPaymentMethodData,
          },
        }))
      setPaymentMethodStatus({
        ...paymentMethodStatus,
        PayPal: {
          isSelected: selectedPaymentMethodId === PAYMENT_METHODS.CHECKOUT_NAMES.PAYPAL,
        },
        ApplePay: {
          isSelected: selectedPaymentMethodId === PAYMENT_METHODS.CHECKOUT_NAMES.APPLE_PAY,
        },
        CyberSourceKlarna: {
          isSelected: selectedPaymentMethodId === PAYMENT_METHODS.CHECKOUT_NAMES.KLARNA,
        },
      })
    } catch (e: any) {
      Log.error('PAYMENT INIT ERROR: ' + e, window.location.href)
    } finally {
      setIsUpdatingPaymentInstructions(false)
    }
  }, [selectedPaymentInfoList])

  useEffect(() => {
    setPaymentProcessing(
      paypalStatus.loading ||
        applePayStatus.loading ||
        klarnaStatus.loading ||
        orderFinalizing ||
        paymentLoading ||
        zeroCheckoutPaymentLoading ||
        paypalFormSubmitting
    )
  }, [
    paypalStatus.loading,
    klarnaStatus.loading,
    applePayStatus.loading,
    orderFinalizing,
    paymentLoading,
    zeroCheckoutPaymentLoading,
    paypalFormSubmitting,
  ])

  useEffect(() => {
    paymentMethodStatus.CyberSourceKlarna.isSelected &&
      klarnaStatus.loaded &&
      klarnaStatus.authorized &&
      void submitOrderWithKlarnaKlarna()
  }, [paymentMethodStatus, klarnaStatus])

  const isZero = parseFloat(cart.grandTotal) === 0

  useEffect(() => {
    if (hasSubscriptionMultipleItemsError) {
      router.push(`/${langCode}/${CART}`)
    }
  }, [hasSubscriptionMultipleItemsError])

  return (
    <>
      <GridItem>
        {isZero && (
          <TotalZeroContainer>
            <TotalZeroLabel>{t('Payment.Labels.FreeCheckout')}</TotalZeroLabel>
            <TotalZeroButtonWrapper>
              <Button
                className="shipping-footer__button"
                data-element-id="X_X_Cart_ProceedToPayment"
                data-testid="shipping-can-continue"
                loading={false}
                type="button"
                variant="primary"
                onClick={() => onSubmit(null)}
              >
                {t('Payment.Actions.ConfirmOrder')}
              </Button>
            </TotalZeroButtonWrapper>
          </TotalZeroContainer>
        )}
        <SelectPaymentContainer isVisible={!isZero}>
          <PaymentMethodSelection
            paymentProcessing={paymentProcessing}
            paymentMethodId={paymentInfo?.payMethodId}
            paymentTermConditionId={paymentInfo?.paymentTermConditionId}
            paymentsList={paymentMethods}
            onSubmit={onSubmit}
            togglePayOption={togglePayOption}
            klarnaPaymentRef={klarnaPaymentRef}
            paymentMethodStatus={paymentMethodStatus}
            order={cart}
            webId={webId}
            setWebId={setWebId}
          >
            <>
              <PayerAuthIFrame
                name={securePaymentStatus?.step === PAYMENT_STEPS.FINALIZATION ? 'step-up-iframe' : 'ddc-iframe'}
                height={securePaymentStatus?.step === PAYMENT_STEPS.FINALIZATION ? '400' : '10'}
                width={securePaymentStatus?.step === PAYMENT_STEPS.FINALIZATION ? '400' : '10'}
                hidden={true}
              />
              <SecurePaymentForm
                id={securePaymentStatus?.step === PAYMENT_STEPS.FINALIZATION ? 'step-up-form' : 'ddc-form'}
                target={securePaymentStatus?.step !== PAYMENT_STEPS.FINALIZATION ? 'ddc-iframe' : undefined}
                formRef={payerAuthFormRef}
                action={
                  securePaymentStatus?.data?.payerAuthEnrollReply_stepUpUrl ||
                  securePaymentStatus?.data?.payerAuthSetupReply_deviceDataCollectionURL ||
                  ''
                }
                token={
                  securePaymentStatus?.data?.payerAuthEnrollReply_accessToken ||
                  securePaymentStatus?.data?.payerAuthSetupReply_accessToken ||
                  ''
                }
              />
            </>
          </PaymentMethodSelection>
        </SelectPaymentContainer>
      </GridItem>
      <Dialog open={showSaveCCDialog}>
        <SaveCreditCardDialog handleClose={() => setShowSaveCCDialog(false)} />
      </Dialog>
    </>
  )
}

export default Payment
