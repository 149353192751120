import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Modal } from '@mui/material'
import { StyledFormControlLabel, StyledRadio } from '../../../components/UI'
import { RemoveAddressDialog } from '../../../components/RemoveAddressDialog'
import { Contact } from '../../../types/user'

import {
  AddressBookContent,
  AddressBoxWrapper,
  AddressButtonWrapper,
  AddressCustomerName,
  AddressLineBreak,
  RemoveButtonWrapper,
  StyledSecondaryText,
  UserAddressBox,
  UserAddressWrapper,
  RemoveAddressModalContainer,
  EditButton,
  RemoveLinkButton,
} from '../AddressBook.style'
import HTMLReactParser from 'html-react-parser'

export interface IProps {
  address: Contact
  updateAddress: (address: Contact) => void
  setIsEditingAddress: (isEditingAddress: boolean) => void
  setAddressSelectedToEdit: (address: Contact) => void
  addressList?: Contact[]
}

const UserAddress: FC<IProps> = ({
  address,
  updateAddress,
  setIsEditingAddress,
  setAddressSelectedToEdit,
  addressList,
}) => {
  const [isRemoveAddressDialogOpen, setIsRemoveAddressDialogOpen] = useState<boolean>(false)
  const handleRemoveAddressOpen = () => setIsRemoveAddressDialogOpen(true)
  const handleRemoveAddressClose = () => setIsRemoveAddressDialogOpen(false)
  const handleEditAddressOpen = () => {
    setIsEditingAddress(true)
    setAddressSelectedToEdit(address)
  }

  const { t } = useTranslation()

  const changeDefaultAddress = newDefaultAddress => {
    addressList?.forEach(address => {
      if (address.primary === 'true') {
        updateAddress({ ...address, primary: 'false' })
      }
    })
    const defaultAddress = { ...newDefaultAddress, primary: 'true' }
    updateAddress(defaultAddress)
  }

  const userAddressArray: string[] = useMemo(() => {
    if (!address) {
      return []
    }

    const { addressLine = [], state, zipCode, city, country, phone1 } = address

    return [`${addressLine[0] || ''}, ${city || ''} ${state} ${zipCode || ''} ${country}`, phone1 || '']
  }, [address])

  return (
    <AddressBookContent key={address.nickName}>
      <Modal
        open={isRemoveAddressDialogOpen}
        onClose={handleRemoveAddressClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <RemoveAddressModalContainer>
          <RemoveAddressDialog userAddress={address} handleClose={handleRemoveAddressClose} />
        </RemoveAddressModalContainer>
      </Modal>
      <AddressBoxWrapper>
        <RemoveButtonWrapper>
          <AddressCustomerName>{t(`${address?.firstName} ${address?.lastName}`)}</AddressCustomerName>
          <AddressButtonWrapper>
            <EditButton
              filltype={'outline'}
              data-element-id={address && 'X_X_AddressBook_Edit'}
              onClick={handleEditAddressOpen}
            >
              {t('AddressBook.EditButton')}
            </EditButton>
            <RemoveLinkButton data-element-id={address && 'X_X_AddressBook_Remove'} onClick={handleRemoveAddressOpen}>
              {t('AddressBook.RemoveButton')}
            </RemoveLinkButton>
          </AddressButtonWrapper>
        </RemoveButtonWrapper>
        <AddressLineBreak />
        <UserAddressWrapper>
          {address && (
            <UserAddressBox>
              {userAddressArray.map(text => (
                <StyledSecondaryText key={text}>{HTMLReactParser(text)}</StyledSecondaryText>
              ))}
            </UserAddressBox>
          )}
        </UserAddressWrapper>
        <StyledFormControlLabel
          control={<StyledRadio />}
          label={
            address?.primary === 'true' ? t('AddressBook.DefaultAddressMsg') : t('AddressBook.SetAsDefaultAddressMsg')
          }
          checked={address?.primary === 'true'}
          onChange={() => changeDefaultAddress(address)}
        />
      </AddressBoxWrapper>
    </AddressBookContent>
  )
}

export default UserAddress
