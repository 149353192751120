import React, { useEffect, useState } from 'react'
import * as userAction from '../../../redux/actions/user'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import Axios, { Canceler } from 'axios'
import { FormProvider, useForm } from 'react-hook-form'
import { GridContainer, GridItem, PreLoader, StyledButtonAsLink } from '../../UI'
import styled from '@mui/material/styles/styled'
import { loginStatusSelector, userDetailsSelector } from '../../../redux/selectors/user'
import { setOpenModalSignIn } from '../../../features/ui/action'
import { useCSRForUser } from '../../../foundation/hooks/useCSRForUser'
import { genericErrorSelector } from '../../../redux/selectors/error'
import { ContentForgotPassword, ErrorMessage } from './SignInFormDialog.style'
import { StyledSignInButton } from '../../../views/SignIn/styles/SignInForm.style'
import { FormControlTextField, EMAIL_VALIDATION_PATTERN, FormControlPasswordField } from '../../UI/Form'
import { useSite } from '../../../foundation/hooks/useSite'
import { useRouter, usePathname } from 'next/navigation'
import { ACCOUNT, ACCOUNT_CHILDREN, CART, CHECKOUT_CHILDREN } from '@constants/routes'
import { sendLoginEvent } from '@foundation/analytics/tealium/lib'
import { ANALYTICS_PAGE_TYPE, useAnalyticsData } from '@foundation/hooks/useAnalyticsData'

export interface FormData {
  email: string
  password: string
}

const defaultValues: FormData = {
  email: '',
  password: '',
}

export const ForgotYourPasswordLink = styled(StyledButtonAsLink)(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 600,
  lineHeight: 1.33,
}))

const SignInFormDialog: React.FC<{
  handleOpenForgotPassword: () => void
  onLoginCallBack: any
}> = ({ handleOpenForgotPassword, onLoginCallBack }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const pathname = usePathname()
  const { t } = useTranslation()
  const { isAuthenticationError } = useCSRForUser()
  const error = useSelector(genericErrorSelector)
  const isErrorLogin = isAuthenticationError(error)
  const userLoggedIn = useSelector(loginStatusSelector) || false
  const [isSubmitting, setSubmitting] = useState(false)
  const { mySite } = useSite()
  let cancels: Canceler[] = []
  const CancelToken = Axios.CancelToken
  const payloadBase: object = {
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
    storeId: mySite.storeID,
  }
  const userDetails = useSelector(userDetailsSelector)
  const commonAnalyticsData = useAnalyticsData(ANALYTICS_PAGE_TYPE.HOME)

  const onSubmit = (data: FormData) => {
    setSubmitting(true)
    dispatch(
      userAction.LOGIN_REQUESTED_ACTION({
        body: {
          logonId: data.email,
          logonPassword: data.password,
        },
        ...payloadBase,
      })
    )
  }

  useEffect(() => {
    if (userLoggedIn) {
      dispatch(
        userAction.FETCH_USER_DETAILS_REQUESTED_ACTION({
          widget: '',
          ...payloadBase,
        })
      )
      sendLoginEvent(userDetails!, commonAnalyticsData)
      onLoginCallBack && onLoginCallBack()
      dispatch(setOpenModalSignIn(false))
      if (pathname.includes(CHECKOUT_CHILDREN.ORDER_CONFIRMATION)) {
        router.push(`/${ACCOUNT}/${ACCOUNT_CHILDREN.DASHBOARD}`)
      }
    }
  }, [userLoggedIn])

  useEffect(() => {
    const hasError = Boolean(error?.errorCode) || Boolean(error?.errorKey)
    if (hasError && isSubmitting) {
      setSubmitting(false)
    }
  }, [isSubmitting, error])

  const methods = useForm<FormData>({
    defaultValues,
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <GridContainer>
          {isErrorLogin && (
            <GridItem xs={12}>
              <ErrorMessage>{t('SignInPage.Msgs.ErrorLogin')}</ErrorMessage>
              <ErrorMessage>{t('SignInPage.Msgs.ErrorLogin2')}</ErrorMessage>
            </GridItem>
          )}

          <GridItem xs={12}>
            <FormControlTextField
              name="email"
              label={t('SignInPage.Fields.Email.Text')}
              rules={{
                required: {
                  value: true,
                  message: t('SignInPage.Fields.Email.Empty'),
                },
                pattern: {
                  value: EMAIL_VALIDATION_PATTERN,
                  message: t('SignInPage.Fields.Email.Invalid'),
                },
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlPasswordField
              name="password"
              label={t('SignInPage.Fields.Password.Text')}
              autoComplete="current-password"
              id="outlined-password-input"
              rules={{
                required: {
                  value: true,
                  message: t('SignInPage.Fields.Password.Empty'),
                },
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <ContentForgotPassword>
              <ForgotYourPasswordLink onClick={handleOpenForgotPassword}>
                {t('SignInPage.CTA.ForgotPassword')}
              </ForgotYourPasswordLink>
            </ContentForgotPassword>
            <StyledSignInButton
              data-element-id="X_X_LoginForm_Login"
              type="submit"
              disabled={isSubmitting}
              variant="primary"
            >
              {isSubmitting && <PreLoader fill={'light'} withButton />}
              {t('SignInPage.CTA.SignIn')}
            </StyledSignInButton>
          </GridItem>
        </GridContainer>
      </form>
    </FormProvider>
  )
}

export default SignInFormDialog
