import React, { useCallback, useEffect, useRef, useState } from 'react'
import { values } from 'lodash-es'
//CONSTS
import { ACCOUNT } from '../../foundation/constants/common'
import { APPLEPAY_ORDER_ID } from '../../constants/checkout'
import { ORDER_CONFIGS } from '../../configs/order'
import { ORDER_STATUS, PO_NUMBER } from '../../constants/order'
import * as PaymentMethod from '../../constants/paymentMethods'
import { PAYMENT_METHODS } from '../../constants/paymentMethods'
//TYPES
import { CheckoutPayload } from '../../types/checkout'
import { IProduct } from '../../types/product'
import { IOrderDetails } from '../../types/order'
//APIS
import {
  orderApi,
  useExecutePreCheckoutMutation,
  useFinalizeOrderWithCybersourceMutation,
  useFindOrderdByIdQuery,
} from '../../features/order/query'
//LIBS
import Axios, { Canceler } from 'axios'
import { parseCatentriesForRX } from '../../foundation/analytics/tealium/formatters/productFormatter'
import { ANALYTICS_IS_REORDER, getPartNumberQuantities, sendTYPData } from '../../foundation/analytics/tealium/lib'
import { monetateMapProductsInCart, monetateTrackPurchase } from '../../foundation/monetate/lib'
import { useSite } from '../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'next-i18next'
//UI
import { StyledOrderConfirmationLoaderWrapper } from './OrderConfirmation.style'
import { PreLoader } from '../../components/UI'
//REDUX
import { useDispatch, useSelector } from 'react-redux'
import {
  cartSelector,
  catentriesSelector,
  orderCompleteSelector,
  orderDetailsSelector,
  orderItemsSelector,
  orderSelector,
} from '../../features/order/selector'
import { genericErrorSelector } from '../../redux/selectors/error'
import { userEmailSelector } from '../../redux/selectors/user'
//UTILS
import Log from '../../services/Log'
import { localStorageUtil, sessionStorageUtil } from '../../foundation/utils/storageUtil'
import { updateUrlParameter } from '../../utils/url'
import { isRxCart, parseOrderItems } from '../../utils/isRxOrder'
import { ANALYTICS_PAGE_TYPE, useAnalyticsData } from '../../foundation/hooks/useAnalyticsData'
import { getCheckoutPaths } from '../../utils/routeUtils'
import { resetReorderId, resetReorderInfo, togglePaypalExpress } from '../../features/order/slice'
import { getInsuranceEventModule } from '../../components/DirectBilling'
import { getCatEntriesAlgoliaPrices } from '../../foundation/algolia/algoliaPrice'
import { useRouter } from 'next/navigation'
import { hasSubscribedItemsInOrder } from '@views/Subscription/helpers/subscriptionHelpers'
import { subscriptionConfigSelector } from '@features/subscription/selector'
import {
  SET_SUBSCRIPTION_LAST_SUBSCRIBED_ORDER_ACTION,
  SET_SUBSCRIPTION_ORDER_COMPLETE_ACTION,
} from '@redux/actions/subscription'
import { useSubmitSubscriptionInfoMutation } from '@features/subscription/query'
import { getOrderPayMethodId } from '@utils/payMethods'
import { clearReorderLocalStorage } from '@utils/order'
import { getProductType } from '@utils/productAttributes'
import { useCheckoutSteps } from '@hooks/useCheckoutSteps'
import productUtils from '@utils/ProductUtils'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
/**
 * Order Confirmation component
 * displays order confirmation info
 * @param props
 */

const OrderConfirmation: React.FC = () => {
  const analyticsDataForTYP = useAnalyticsData(ANALYTICS_PAGE_TYPE.CHECKOUT_SUMMARY_AND_CONFIRMATION)
  const email = useSelector(userEmailSelector)
  const orderItems = useSelector(orderItemsSelector)
  const catEntries = useSelector(catentriesSelector)
  const order = useSelector(orderSelector)
  const orderDetails = useSelector(orderDetailsSelector) || null
  const [orderFinalizeError, setOrderFinalizeError] = useState<string>()
  const [isPaypalExpress, setIsPaypalExpress] = useState<boolean>(false)
  const [isPlacingOrder, setIsPlacingOrder] = useState<boolean>(false)
  const { mySite } = useSite()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : ''
  const cart = useSelector(cartSelector)
  const { lastSubscribedOrderId } = useSelector(subscriptionConfigSelector)
  const orderComplete =
    useSelector(orderCompleteSelector) || (!!lastSubscribedOrderId && lastSubscribedOrderId === cart.orderId)
  const commonError: Record<string, string> = useSelector(genericErrorSelector)
  const { langCode } = useStoreIdentity()
  const checkoutPaths = getCheckoutPaths(langCode)
  const mockDataEnabled = false
  const payloadBase = {
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }
  const router = useRouter()
  const firstLoad = useRef(true)

  const payloadBaseCheckout: CheckoutPayload = {
    currency: defaultCurrencyID,
    storeId: mySite.storeID,
    responseFormat: 'application/json',
    ...payloadBase,
  }

  const IS_RX_CART = isRxCart(cart?.orderExtendAttribute)
  //TODO: use updated function for parsed orders
  const PARSED_ORDER_ITEMS = IS_RX_CART ? parseOrderItems(orderItems) : orderItems

  const [finalizeOrderWithCybersource] = useFinalizeOrderWithCybersourceMutation()
  const [executePreCheckout] = useExecutePreCheckoutMutation()

  const [paypalExpressCheckStatus] = orderApi.endpoints.paypalExpressCheckStatus.useLazyQuery()
  const [submitSubscriptionInfo] = useSubmitSubscriptionInfoMutation()
  const { enabled: isSubscriptionEnabled } = useSelector(subscriptionConfigSelector)
  const customerSegment = useCustomerSegmentsUtil()
  const algoliaPrices = getCatEntriesAlgoliaPrices(customerSegment, catEntries)
  const { refetch: refetchOrder } = useFindOrderdByIdQuery({
    storeId: mySite.storeID,
    orderId: cart?.orderId || localStorageUtil.get(APPLEPAY_ORDER_ID),
  })
  const { completeCheckoutStep } = useCheckoutSteps()

  useEffect(() => {
    return () => {
      localStorage.removeItem('checkoutCompletedStep')
    }
  }, [completeCheckoutStep])

  const finalizeCheckout = async (orderDetails: IOrderDetails) => {
    const payloadBodyBase = {}
    try {
      const orderId = cart.orderId
      const orderPaymentMethodId = getOrderPayMethodId(orderDetails)
      const isPaylPal = orderPaymentMethodId === PAYMENT_METHODS.CHECKOUT_NAMES.PAYPAL
      const isPaypalExpress = isPaylPal && isPaypalExpressOrder(orderDetails)
      const orderFinalizationPayload: CheckoutPayload = {
        ...payloadBaseCheckout,
        orderId: orderId,
        ppExpress: isPaypalExpress,
        sortOrderItemBy: ORDER_CONFIGS.sortOrderItemBy,
        body: {
          ...payloadBodyBase,
          orderId: orderId,
          notifyOrderSubmitted: '1',
        },
      }

      const placeOrderWithSubscription = isSubscriptionEnabled && hasSubscribedItemsInOrder(cart, null)
      if (isPaypalExpress) {
        const pExpressStatus = await paypalExpressCheckStatus(orderFinalizationPayload)
        if (pExpressStatus?.data?.decision === 'ACCEPT') {
          await placeOrder(placeOrderWithSubscription, orderFinalizationPayload)
        } else {
          throw { message: 'Paypal express check status failed.' }
        }
      } else {
        await placeOrder(placeOrderWithSubscription, orderFinalizationPayload)
      }
    } catch (e) {
      console.error('UNABLE TO FINALIZE ORDER: ' + e, window.location.href)
    }
  }

  const placeOrder = async (placeOrderWithSubscription, orderFinalizationPayload) => {
    setIsPlacingOrder(true)
    let orderFinalizedInfo

    await executePreCheckout(orderFinalizationPayload).catch(error => {
      throw error
    })

    if (placeOrderWithSubscription) {
      orderFinalizedInfo = await submitSubscriptionInfo({
        storeId: mySite.storeID,
        orderItemId: cart.orderId,
      })
        .then(() => {
          refetchOrder()
          setIsPlacingOrder(false)
        })
        .catch(error => {
          throw error
        })
        .finally(() => setIsPlacingOrder(false))

      await dispatch(SET_SUBSCRIPTION_LAST_SUBSCRIBED_ORDER_ACTION(cart.orderId))
      await dispatch(SET_SUBSCRIPTION_ORDER_COMPLETE_ACTION(true))
    } else {
      await finalizeOrderWithCybersource(orderFinalizationPayload)
        .catch(error => {
          throw error
        })
        .then(() => {
          refetchOrder()
        })
        .finally(() => setIsPlacingOrder(false))
    }

    localStorageUtil.set('rxFlowTypeMap', '[]')
  }

  const resetOrderInfo = useCallback(async () => {
    if (!cart?.orderId) {
      return
    }
    Log.info('resetOrderInfo')
    clearReorderLocalStorage()
    await dispatch(resetReorderId())
    await dispatch(resetReorderInfo())
    localStorageUtil.remove(ACCOUNT + '-' + PO_NUMBER + '-' + cart?.orderId)
    localStorageUtil.remove(APPLEPAY_ORDER_ID)
  }, [cart?.orderId])

  const redirectToShipping = () => {
    const errorUrl = updateUrlParameter(
      isPaypalExpress ? checkoutPaths.cart : checkoutPaths.payment,
      'orderError',
      orderFinalizeError
    )
    return router.push(errorUrl)
  }

  const getPayMethodDescription = (orderPayMethod: PaymentMethod.CheckoutName) => {
    const payMethodDescription = orderPayMethod
      ? (PAYMENT_METHODS.CHECKOUT_CC_NAMES as PaymentMethod.CheckoutCCName).includes(orderPayMethod)
        ? t('OrderConfirmation.Msgs.PayMethodCC')
        : orderPayMethod
      : ''
    return payMethodDescription
  }

  useEffect(() => {
    const manageOrder = async () => {
      try {
        if (!orderDetails) {
          return
        }

        shouldOrderBeClosed(orderDetails) && (await finalizeCheckout(orderDetails))

        orderDetails?.orderComplete && getInsuranceEventModule().onOrderComplete()

        if (orderComplete && catEntries && firstLoad.current) {
          console.log('The order has been complete')
          const productsForMonetate = monetateMapProductsInCart(orderItems)
          const products = values(catEntries) as IProduct[]
          const partNumberQuantities = getPartNumberQuantities(PARSED_ORDER_ITEMS)
          if (IS_RX_CART) {
            const frameCatEntries = parseCatentriesForRX(
              PARSED_ORDER_ITEMS,
              products.filter(y => getProductType(y) !== 'Rox_lens' && getProductType(y) !== 'Rox_service'),
              algoliaPrices
            )
            const combinedAlgoliaPrices = productUtils.combineAlgoliaOpticalPrices(frameCatEntries, algoliaPrices)

            sendTYPData({
              common: analyticsDataForTYP,
              products: frameCatEntries,
              email,
              order,
              partNumberQuantities,
              algoliaPrices: combinedAlgoliaPrices,
            })
          } else {
            sendTYPData({
              common: analyticsDataForTYP,
              products,
              email,
              order,
              partNumberQuantities,
              algoliaPrices,
            })
          }

          monetateTrackPurchase({ purchaseId: orderDetails?.orderId, productsInCart: productsForMonetate })

          sessionStorageUtil.remove(ANALYTICS_IS_REORDER)
          firstLoad.current = false

          completeCheckoutStep('order-confirmation')
          localStorage.removeItem('acceptedNewsletterFlagUrl')
        }

        const isPayPal = isPaypalExpressOrder(orderDetails)
        setIsPaypalExpress(isPayPal)
        isPayPal && (await dispatch(togglePaypalExpress(isPayPal)))
      } catch (e: any) {
        console.error('ORDER DETAILS GET ERROR:' + e, window.location.href)
      }
    }
    if (!!orderDetails) {
      void manageOrder()
    }
  }, [orderComplete, orderDetails])

  useEffect(() => {
    setOrderFinalizeError(commonError.errorMessage)
  }, [commonError])

  useEffect(() => {
    if (orderComplete) {
      resetOrderInfo()
    }
  }, [orderComplete, resetOrderInfo])

  const isPaypalExpressOrder = (orderData: IOrderDetails): boolean => {
    return (
      !!orderData?.paymentInstruction.find(pi => !!pi)?.protocolData.find(el => el.name === 'isPayPalExpress')?.value ||
      false
    )
  }

  const shouldOrderBeClosed = (orderData: IOrderDetails): boolean => {
    const orderPaymentMethod =
      orderData && getPayMethodDescription(getOrderPayMethodId(orderData) as PaymentMethod.CheckoutName)
    return (
      !orderComplete &&
      !isPlacingOrder &&
      ![ORDER_STATUS.Created, ORDER_STATUS.PendingPrescription, ORDER_STATUS.Hold].includes(orderData.orderStatus) &&
      orderPaymentMethod !== PAYMENT_METHODS.CHECKOUT_NAMES.APPLE_PAY
    )
  }

  if (orderFinalizeError) {
    redirectToShipping()
  }

  return !(orderComplete || mockDataEnabled) ? (
    <StyledOrderConfirmationLoaderWrapper>
      <PreLoader size={20} />
    </StyledOrderConfirmationLoaderWrapper>
  ) : null
}

export default OrderConfirmation
