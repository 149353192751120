import { usePageType } from '@foundation/hooks/usePageType'
import Router from 'next/router'
import { RefObject, useEffect, useState } from 'react'
import { PAGE_TYPES } from '../foundation/analytics/tealium/pageTypes'
import { getSessionItem, SessionPrefix, setSessionItem } from '../sessionStorage'

const scrollToPos = (x: number, y: number, scrollContainer: HTMLElement) => {
  const scrollTimeout = setTimeout(() => {
    scrollContainer.scrollTo(x, y)
    clearTimeout(scrollTimeout)
  }, 40)
}

const saveScrollPosition = (url: string, scrollContainer: HTMLElement | null) => {
  if (scrollContainer) {
    const scrollPos = { x: scrollContainer.scrollLeft, y: scrollContainer.scrollTop }
    setSessionItem({ key: url, value: scrollPos, prefix: SessionPrefix.SCROLL_PAGE })
    scrollToPos(0, 0, scrollContainer)
  }
}

const restoreScrollPosition = (url: string, scrollContainer: HTMLElement | null) => {
  if (scrollContainer) {
    const storedScrollPos = getSessionItem({ key: url, prefix: SessionPrefix.SCROLL_PAGE })
    if (storedScrollPos) {
      scrollToPos(storedScrollPos.x, storedScrollPos.y, scrollContainer)
    }
  }
}

interface IScrollRestoration {
  routerPath: string
  scrollContainerRef: RefObject<HTMLElement> | null
  scrollPageType: keyof typeof PAGE_TYPES
  backPageType: keyof typeof PAGE_TYPES
}

const useScrollRestoration = ({ routerPath, scrollContainerRef, scrollPageType, backPageType }: IScrollRestoration) => {
  const [isBackNavigation, setIsBackNavigation] = useState(false)
  const { pageType } = usePageType()

  useEffect(() => {
    const scrollContainer = scrollContainerRef?.current

    const handlePopState = () => {
      setIsBackNavigation(true)
    }

    const handleRouteChangeStart = () => {
      if (pageType === scrollPageType && scrollContainer) {
        saveScrollPosition(routerPath, scrollContainerRef.current)
      }
    }

    const handleRouteChangeComplete = () => {
      if (pageType === scrollPageType && isBackNavigation && scrollContainer) {
        restoreScrollPosition(routerPath, scrollContainerRef.current)
        setIsBackNavigation(false)
      }
    }

    if (
      typeof window !== 'undefined' &&
      scrollContainerRef?.current &&
      (pageType === scrollPageType || pageType === backPageType) &&
      scrollContainer &&
      'scrollRestoration' in window.history
    ) {
      window.history.scrollRestoration = 'manual'
      window.addEventListener('popstate', handlePopState)
      Router.events.on('routeChangeStart', handleRouteChangeStart)
      Router.events.on('routeChangeComplete', handleRouteChangeComplete)
    }

    return () => {
      window.removeEventListener('popstate', handlePopState)
      Router.events.off('routeChangeStart', handleRouteChangeStart)
      Router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [routerPath, scrollContainerRef, pageType, isBackNavigation, scrollPageType, backPageType])
}

export default useScrollRestoration
